<script setup lang="ts">
import { useEventListener } from '@vueuse/core';
import { CssVariables } from '@common/enums/cssVariables';

const tableController = useTableController();
const cssVariable = useCssVariable();

const { tableNumber } = storeToRefs(tableController);

const inputRef = ref<HTMLInputElement | null>(null);
const showKeyboard = ref(false);

defineProps({
  position: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['next']);

const collapse = defineModel({
  type: Boolean,
  required: true,
});

function closeKeyboard() {
  inputRef.value?.blur();
  showKeyboard.value = false;
  emit('next');
}

useEventListener('paste', (event) => {
  event.preventDefault();
  const pastedData = event.clipboardData?.getData('text');
  if (pastedData) {
    const pastedTableNum = pastedData.replace(/\D/g, '');
    tableController.tableNumber = pastedTableNum;
    closeKeyboard();
  }
});

onMounted(() => {
  inputRef.value?.focus();
});
</script>

<template>
  <CollapsableContainer
    v-model="collapse"
    :show-collapse="false"
    :position="position"
    :title="$t('CHECKOUT.TABLE')"
  >
    <section class="flex flex-col items-center gap-8">
      <button
        type="button"
        @click="showKeyboard = true"
        for="device-number"
        class="relative w-48 h-48 p-4 rounded-full"
        :style="{
          backgroundColor: cssVariable.darken(CssVariables.PRIMARY),
        }"
      >
        <input
          id="device-number"
          type="text"
          class="absolute opacity-0"
          v-model="tableNumber"
          ref="inputRef"
        />
        <label
          for="device-number"
          class="flex items-center justify-center w-full h-full rounded-full bg-kiosk-primary"
        >
          <span class="font-bold text-white text-7xl">{{
            tableNumber || '00'
          }}</span>
        </label>
      </button>

      <p
        v-if="tableController.allowQrScanTableNumber"
        class="flex gap-2 py-3 text-lg italic leading-none label-text-alt text-info"
      >
        <span class="icon icon-info-circle" /> {{ tableController.allowQrScanTableNumber ? $t('SCAN_QR_OR_TABLE_NUMBER') : $t('INSERT_TABLE_NUMBER') }}
      </p>

      <div
        v-if="tableController.displayPickupAtCounter"
        class="flex gap-5 text-3xl w-[35rem] items-center"
      >
        <div class="separator w-full h-0.5 bg-neutral-400 my-4" />
        <span class="flex-grow text-nowrap">{{
          $t('CHECKOUT.YOU_CAN_ALSO')
        }}</span>
        <div class="separator w-full h-0.5 bg-neutral-400 my-4" />
      </div>

      <KioskButton
        v-if="tableController.displayPickupAtCounter"
        text-size="small"
        class="text-3xl"
        @on-click="tableController.pickUpAtCounter({
          next: closeKeyboard,
        })"
      >
        {{ $t('PICK_UP_COUNTER') }}
      </KioskButton>
    </section>
  </CollapsableContainer>

  <SlideTransition class="fixed bottom-0">
    <template v-if="showKeyboard">
      <NumbersKeyboard
        v-model="tableNumber"
        :input-ref="inputRef"
        :max-length="2"
        @close="closeKeyboard()"
      />
    </template>
  </SlideTransition>
</template>

<style scoped>
.custom-input-container {
  @apply flex flex-col justify-center gap-2 w-[556px];
}

.customer-info {
  @apply flex flex-col items-center justify-center gap-10 my-2 p-8;
}

.customer-item {
  @apply shadow-xl flex justify-center items-center gap-8 px-5 py-3 w-full;
}

.item-input {
  @apply h-14 w-96 bg-neutral-200 placeholder:text-neutral-300 text-[32px] font-medium leading-[101px] tracking-[-1.263px] px-4 focus:outline-none;
}
</style>
