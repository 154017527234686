/* eslint-disable no-unused-vars */
export enum PinPadBrands {
  FISERV = 1,
  CREDIBANCO = 2,
  VERIFONE = 3,
  ADYEN = 4,
  BILLPOCKET = 5,
  CARDNET = 6,
  NETPAY = 7,
  REDEBAN = 8,
}
