<script lang="ts" setup>
const props = defineProps({
  steps: {
    type: Number,
    required: true,
    validator: (value: number) => value > 0,
  },
  currentStep: {
    type: Number,
    required: true,
  },
});

// Emit events
const emit = defineEmits<{
  closeModal: [];
  goToNextStep: [];
  goToPreviousStep: [];
}>();

const { createOrderType } = useOrderTypeStore();

const { createOrder } = useCreateOrder();
const cart = useCartStoreV2();
const { subtotal: total } = storeToRefs(cart);
const metadataStoreV2 = useMetadataStoreV2();
const { clusterSettings } = storeToRefs(metadataStoreV2);
const tipsController = useTipsController();
const { tips, tipSelected, hasGamification } = storeToRefs(tipsController);
const { t } = useI18n();

const leftbuttonText = computed(() =>
  (props.currentStep !== props.steps ? t('TIPS.OMIT_AND_CONTINUE') : t('TIPS.OMIT_AND_PAY')));
const rightbuttonText = computed(() =>
  (props.currentStep !== props.steps ? t('TIPS.ADD_AND_CONTINUE') : t('TIPS.ADD_AND_PAY')));

const disabledLeftButton = computed(
  () => tipSelected.value !== 0 && props.currentStep === 0,
);

function toggleTip(option: number) {
  if (tipSelected.value === option) {
    tipSelected.value = 0;
    return;
  }
  tipSelected.value = option;
}

async function createCashOrder() {
  await createOrder(createOrderType);
}

async function rightButtonActualFunction() {
  if (props.currentStep !== props.steps) emit('goToNextStep');
  else {
    emit('closeModal');
    await createCashOrder();
  }
}

async function leftButtonActualFunction() {
  if (props.currentStep === props.steps) {
    emit('closeModal');
    await createCashOrder();
  } else emit('goToNextStep');
}

function getImageUrl(index: number) {
  return new URL(`/src/assets/images/Coin${index + 1}.webp`, import.meta.url).href;
}
</script>

<template>
  <div class="flex flex-col items-end justify-between w-full h-full">
    <div class="flex flex-col items-center justify-center w-full h-full gap-11">
      <div class="flex flex-col items-center gap-3 text-center max-w-[585px]">
        <h2 class="text-[40px] text-balance font-medium text-center leading-10 uppercase">
          {{ $t('TIPS.MODAL_TITLE') }}
        </h2>
        <p class="text-3xl text-center text-gray-600">
          {{ $t('TIPS.MODAL_DESCRIPTION') }}
        </p>
      </div>
      <div class="grid grid-cols-2 gap-8">
        <template v-for="(option, index) in tips" :key="option">
          <label
            :tabindex="option"
            class="bg-[linear-gradient(129deg,#F8F8F8_2.02%,#F0F0F0_46.38%,#F8F8F8_94.44%)] relative transition-all flex items-center justify-center h-[204px] w-[204px] py-[30px] px-[40px] border-2 rounded-[28px] cursor-pointer"
            :class="[
              tipSelected === option
                ? 'border-kiosk-btnPrimaryBg shadow-lg'
                : 'border-[#D2CFCF]',
            ]"
            @click="toggleTip(option)"
            @keydown.enter="toggleTip(option)"
            @keydown.space="toggleTip(option)"
          >
            <input
              type="radio"
              v-model="tipSelected"
              class="absolute inset-0 opacity-0 cursor-pointer"
              :value="option"
            />
            <div class="text-center">
              <div class="flex items-center justify-center h-[68px] text-5xl font-medium">
                <img
                  v-if="hasGamification"
                  :src="getImageUrl(index)"
                  :alt="option.toString()"
                  class="w-[68px] h-[68px] bg-contain"
                />
                <span v-else> {{ option }}%</span>
              </div>
              <span
                class="block text-3xl"
                :class="{
                  'font-medium': tipSelected === option,
                }"
              >
                {{ (option * (total / 100)).toFixed(2) }}
              </span>
              <span class="text-xl text-gray-500">
                {{ clusterSettings?.currency.preferredCurrencyCode }}
              </span>
            </div>
          </label>
        </template>
      </div>
    </div>
    <div class="flex items-end justify-between w-full gap-8 mt-10">
      <KioskButton
        @click="leftButtonActualFunction()"
        :disabled="disabledLeftButton"
        text-size="small"
        class="!w-full !px-5"
      >
        {{ leftbuttonText }}
      </KioskButton>
      <KioskButton
        @click="rightButtonActualFunction()"
        :disabled="!tipSelected"
        color="primary"
        text-size="small"
        class="!w-full !px-5"
      >
        {{ rightbuttonText }}
      </KioskButton>
    </div>
  </div>
</template>
