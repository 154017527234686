import { DeepPartial } from '@common/interfaces/deepPartial';
import { parseModifiers } from '@common/utils/toHubsterModifiers';

import { PaymentMethod } from '@slabcode/hubster-models/enums/hubster';
import { HubsterCreateOrderPayload } from '@slabcode/hubster-models/hubster/payloads/manager_orders';
import { ItemDescriptor } from '@slabcode/hubster-modifiers-utils';
import { HubsterOrderItem } from '@slabcode/hubster-models/hubster/payloads/manager_orders/create-order/item';
import { Languages } from '@slabcode/kiosks-core/enums';

import { v4 as uuidv4 } from 'uuid';

import { OrderCreationDto } from '../interfaces/orderCreationDto';

function parseItems(items: ItemDescriptor[]): HubsterOrderItem[] {
  return items.map((item) => ({
    quantity: item.quantity,
    id: item.itemMeta.id,
    name: item.itemMeta.name,
    price: item.itemMeta.price.amount,
    modifiers: parseModifiers(item.modifiers),
    categoryId: item.category.id,
    categoryName: item.category.name,
  }));
}

export function useCreateOrder() {
  const { storeId } = useKioskInfoStore();
  const { items, orderTotal } = useCartStoreV2();
  const { customer } = storeToRefs(useWebhookStoreV2());
  const { fulfillmentMode } = useOrderTypeStore();
  const { tableNumber } = useTableController();
  const { createOrder: webhookOrder } = useWebhookStoreV2();
  const { currentLanguage } = storeToRefs(useLanguageStore());
  const currency = useCurrencyController();
  const inactiveStoreV2 = useInactiveStoreV2();
  const legalPolicies = useLegalPolicies();
  const sessionId = uuidv4();
  const couponStore = useCouponStore();
  const { currencyCode } = storeToRefs(currency);

  async function createOrder(paymentMethod: PaymentMethod): Promise<void> {
    inactiveStoreV2.pauseChecking();

    const { clientType, taxIdentificationNumber, ...rest } = customer.value;

    const order: DeepPartial<HubsterCreateOrderPayload> = {
      items: parseItems(items.map((obj) => obj.item)),
      orderedAt: new Date().toLocaleString('sv'),
      customer: {
        ...rest,
        personalIdentifiers: {
          taxIdentificationNumber,
        },
      },
      orderTotal: orderTotal(),
      customerPayments: [
        {
          value: orderTotal().total,
          paymentMethod,
        },
      ],
      fulfillmentInfo: {
        tableIdentifier: tableNumber,
        fulfillmentMode,
      },
    };

    const webhookBody: OrderCreationDto = {
      storeId,
      sessionId,
      order,
      metadata: {
        language: currentLanguage.value.toUpperCase() as Languages,
        currency: currencyCode.value,
        clientType,
        legal: legalPolicies.acceptedCheckbox,
        couponIds: couponStore.couponIds,
      },
    };

    await webhookOrder(webhookBody);
  }

  return {
    createOrder,
  };
}
