<script lang="ts" setup>
import { RouteName } from '@common/routes/routeName';
import { formatCurrency } from '@common/utils/formatCurrency';

const router = useRouter();
const metadataStoreV2 = useMetadataStoreV2();
const { priceDecimals } = storeToRefs(metadataStoreV2);
const menuStore = useMenuStore();
const { productsCurrency } = storeToRefs(menuStore);
const cartStoreV2 = useCartStoreV2();
const { items, subtotal } = storeToRefs(cartStoreV2);
const { continueCustomization } = useCustomize();
const { triggerViewCart, triggerGoBack } = useGTMEventsComposableV2();
const route = useRoute();
const {
  decreaseCartItemQuantity,
  increaseCartItemQuantity,
  removeItem,
} = useCartItemHandler();
const couponStore = useCouponStore();

const showQRModal = ref<boolean>(false);

const totalCart = computed(() => subtotal.value - couponStore.discount);

function goBack() {
  router.back();
  triggerGoBack(route.name);
}

onBeforeMount(() => {
  triggerViewCart(items.value, subtotal.value);
});

onMounted(() => {
  couponStore.revalidateCoupon();
});
</script>

<template>
  <section class="flex flex-col justify-between full-hd bg-kiosk-background">
    <section class="h-full pb-32 overflow-auto animate__animated animate__fadeInLeft">
      <UpSellingBanners />

      <NotCartItems v-if="items.length === 0" />
      <div class="px-10 py-5" v-else>
        <TransitionGroup name="add-animation">
          <ShoppingCartItemV2
            v-for="(item, i) in items"
            :key="item.item.itemMeta.id"
            :item="item.item"
            :description="item.description"
            :currency="productsCurrency"
            :price-decimals="priceDecimals"
            @add="increaseCartItemQuantity(item.item, i)"
            @sub="decreaseCartItemQuantity(item.item, i)"
            @delete="removeItem(item.item, i)"
            @edit="continueCustomization($event, i)"
          />
        </TransitionGroup>
      </div>
    </section>

    <CouponActions
      v-if="metadataStoreV2.kioskSettings?.coupons.cartView.enabled"
      @show-modal="showQRModal = true"
    />

    <div class="border-t border-black">
      <CartActions
        :disabled="items.length === 0"
        @back="goBack()"
        @next="router.push({ name: RouteName.CHECKOUT })"
        :action-lbl="$t('TIMELINE.PAY')"
      >
        <template #price>
          <strong class="ml-5" v-if="couponStore.discount === 0">
            {{ formatCurrency(productsCurrency, subtotal, priceDecimals) }}
          </strong>
          <p class="ml-5 line-through" v-if="couponStore.discount > 0">
            {{ formatCurrency(productsCurrency, subtotal, priceDecimals) }}
          </p>
          <strong class="ml-5" v-if="couponStore.discount > 0">
            {{ formatCurrency(productsCurrency, totalCart, priceDecimals) }}
          </strong>
        </template>
      </CartActions>
    </div>
  </section>

  <CouponQRModal v-if="showQRModal" @cancel="showQRModal = false" />
</template>

<style scoped>
.cart-amount {
  @apply relative p-3 h-[92px] w-full text-3xl flex items-center justify-center text-white;
}

.back-button {
  @apply absolute left-5 top-1/2 -translate-y-1/2 w-10 h-10;
}

.text-separator {
  @apply border-r h-10 border-white mx-8 bg-white;
}

.add-animation-enter-active {
  animation: add-slowly 0.5s both;
}
.add-animation-leave-active {
  animation: add-slowly 0.5s both reverse;
}

@keyframes add-slowly {
  from {
    opacity: 0;
    transform: translateY(-70%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
