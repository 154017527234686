<script setup lang="ts">
import { PaymentMethod } from '@slabcode/hubster-models/enums/hubster';
import CurrencyModal from './CurrencyModal.vue';
import TipsModal from './TipsModal.vue';

const emit = defineEmits<{
  closeModal: [];
}>();

const { createOrderType } = useOrderTypeStore();
const { createOrder } = useCreateOrder();
const tipsController = useTipsController();
const currencyController = useCurrencyController();

const vIndex = ref<number>(0);

const items = [
  { title: 'TipsModal', enable: tipsController.hasTipModal, component: TipsModal },
  {
    title: 'CurrencyModal',
    enable: currencyController.hasCurrencyModal && createOrderType === PaymentMethod.CARD,
    component: CurrencyModal,
  },
];
const modalViews = computed(() => items.filter((i) => i.enable));

function handleGoToNextStep() {
  if (vIndex.value >= modalViews.value.length - 1) emit('closeModal');
  else vIndex.value += 1;
}

function handleGoToPreviousStep() {
  vIndex.value -= 1;
}

onBeforeMount(async () => {
  if (modalViews.value.length === 0) {
    await createOrder(createOrderType);
  }
});
</script>

<template>
  <StepsModal
    v-if="modalViews.length > 0"
    :titles="modalViews.map((view) => view.title)"
    v-model="vIndex"
  >
    <component
      :is="modalViews[vIndex].component"
      :key="vIndex"
      class="active-modal-step"
      :steps="modalViews.length - 1"
      :current-step="vIndex"
      @close-modal="$emit('closeModal')"
      @go-to-next-step="handleGoToNextStep()"
      @go-to-previous-step="handleGoToPreviousStep()"
    />
  </StepsModal>
</template>
