export const useCurrencyController = defineStore('currency', () => {
  const metadataStoreV2 = useMetadataStoreV2();
  const { clusterSettings } = storeToRefs(metadataStoreV2);

  const currencyCode = ref(clusterSettings.value?.currency?.preferredCurrencyCode);
  const hasCurrencyModal = computed(
    () =>
      clusterSettings.value?.basics.pinpad.pinpadBrand
      && CLOUD_PINPADS.includes(clusterSettings.value.basics.pinpad.pinpadBrand)
      && clusterSettings.value.currency.conversions?.length > 0,
  );

  function clear() {
    currencyCode.value = clusterSettings.value?.currency?.preferredCurrencyCode;
  }

  return { currencyCode, hasCurrencyModal, clear };
});
