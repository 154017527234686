import { DeepPartial } from '@common/interfaces/deepPartial';
import { FulfillmentMode } from '@common/enums/FulfillmentMode';

import { HubsterCreateOrderPayload } from '@slabcode/hubster-models/hubster/payloads/manager_orders';
import { PaymentMethod } from '@slabcode/hubster-models/enums/hubster';

import { defineStore } from 'pinia';

import { JobStatus } from '../enums/jobStatus';
import { Customer } from '../interfaces/customer';
import { JobResponseDto } from '../interfaces/job-response';
import { OrderCreationDto } from '../interfaces/orderCreationDto';

type State = {
  jobResponse: JobResponseDto | null;
  cardProcess: boolean;
  retry: number;
  internalError: boolean;
  paymentInProgress: boolean;
  timeout: NodeJS.Timeout | null;
  paymentType: PaymentMethod | null;
  customer: Customer;
  currentOrder: DeepPartial<HubsterCreateOrderPayload> | null;
  callbackError: string | null;
  fullJobId: string;
};

export const useWebhookStoreV2 = defineStore('webhookV2', {
  state: (): State => ({
    jobResponse: null,
    cardProcess: false,
    internalError: false,
    paymentInProgress: false,
    retry: 0,
    timeout: null,
    paymentType: null,
    customer: {
      name: '',
      email: '',
      phone: '',
      taxIdentificationNumber: '',
      clientType: undefined,
    },
    currentOrder: null,
    callbackError: null,
    fullJobId: '',
  }),

  getters: {
    orderId: (state) => {
      if (!state.jobResponse) return null;

      return state.jobResponse.orderId || state.jobResponse.posInfo?.orderId;
    },

    /**
     * Check if is a success cash transaction
     * @param state current state
     */
    isSuccessCashTransaction: ({ jobResponse, cardProcess }) => {
      if (!jobResponse) return false;
      const { status } = jobResponse;
      return status === JobStatus.INJECTION_SUCCEEDED && !cardProcess;
    },

    /**
     * Check if is a success card transaction
     * @param state current state
     */
    isSuccessCardTransaction: ({ jobResponse, cardProcess }) => {
      if (!jobResponse) return false;
      const { status } = jobResponse;
      return status === JobStatus.INJECTION_SUCCEEDED && cardProcess;
    },

    /**
     * Check if is a card transaction still in progress
     * @param state current state
     */
    isProcessCardTransaction: (state) => {
      const { jobResponse, cardProcess } = state;
      if (!jobResponse) return false;
      const { status } = jobResponse;
      return status === JobStatus.PROCESSING && cardProcess;
    },

    /**
     * Check if is the first card failed attempt
     * @param state current state
     */
    isFirstRejected: ({ jobResponse, retry }) => {
      if (!jobResponse) return false;

      const { status } = jobResponse;
      const fail = status === JobStatus.PAYMENT_FAILED;
      const firstError = retry < 2;
      return fail && firstError;
    },

    /**
     * Check if the card payment is already retried and still failing
     * @param state current state
     */
    isPaymentRejected: ({ jobResponse, retry }) => {
      if (!jobResponse) return false;
      const { status } = jobResponse;
      const fail = status === JobStatus.PAYMENT_FAILED;
      const isOtherAttempt = retry > 0;
      return fail && isOtherAttempt;
    },

    /**
     * Injected to hubster successfully but not in PRB
     */
    isSuccessfullyButPOSFails({ jobResponse }) {
      if (!jobResponse) return false;
      const { status } = jobResponse;
      return status === JobStatus.INJECTION_FAILED;
    },
  },

  actions: {
    /**
     * Create the order structure and send the webhook
     *
     * @param {CartOrderItem[]} items the products added to cart
     * @param {number} total the total cost of that products
     */
    async createOrder(
      webhookBody: OrderCreationDto,
    ): Promise<void> {
      this.paymentType = webhookBody.order.customerPayments![0]!.paymentMethod!;
      this.currentOrder = webhookBody.order;

      const { triggerBeginCheckout } = useGTMEventsComposableV2();
      const { items } = useCartStoreV2();
      const { clear } = useCurrencyController();
      // Trigger GTM events
      triggerBeginCheckout({
        paymentMethod: this.currentOrder.customerPayments![0]!.paymentMethod!,
        customer: this.currentOrder.customer!,
        fulfillmentMode: this.currentOrder.fulfillmentInfo!.fulfillmentMode! as FulfillmentMode,
        tableIdentifier: this.currentOrder.fulfillmentInfo?.tableIdentifier,
        items: toGTMCartItems(items),
        attempt: this.retry + 1,
      });

      try {
        // Start order
        this.paymentInProgress = true;
        this.jobResponse = null;
        this.cardProcess = false;
        const newOrder = (await requests.post(
          '/orders/create',
          webhookBody,
        )) as JobResponseDto;
        const res = (await requests.get(
          `jobs/${newOrder.jobId}`,
        )) as JobResponseDto;
        this.jobResponse = res;
        this.fullJobId = newOrder.jobId;
        await this.validateStatus(res.status, newOrder.jobId);
      } catch (error: any) {
        if (error.message) {
          const inactiveStoreV2 = useInactiveStoreV2();
          inactiveStoreV2.resumeChecking();
          this.callbackError = error.message;
          clear();
        }
      }
    },

    /**
     * Create a recursive function that is called to verify the new order state
     * @param status current order status
     * @param jobId current order id
     */
    async validateStatus(status: JobStatus, jobId: string) {
      // Finished webhook type
      if (this.isFinalStatus(status)) {
        const inactiveStoreV2 = useInactiveStoreV2();

        this.paymentInProgress = false;
        // Resume the inactivity checking after finish the process
        inactiveStoreV2.resumeChecking();
        return;
      }

      try {
        const res = (await requests.get(`jobs/${jobId}`)) as JobResponseDto;
        this.jobResponse = res;
        this.timeout = setTimeout(async () => {
          await this.validateStatus(res.status, jobId);
        }, 4000);
      } catch {
        this.internalError = true;
      }
    },

    isFinalStatus(status: JobStatus) {
      return status === JobStatus.PAYMENT_FAILED
        || status === JobStatus.INJECTION_FAILED
        || status === JobStatus.INJECTION_SUCCEEDED
        || status === JobStatus.INJECTION_IGNORE
        || status === JobStatus.UNKNOWN_ERROR;
    },

    /**
     * Reset job id
     */
    restartWebhook() {
      if (this.timeout) clearTimeout(this.timeout);
      // Reset state
      this.$state = {
        jobResponse: null,
        cardProcess: false,
        internalError: false,
        paymentInProgress: false,
        retry: 0,
        timeout: null,
        paymentType: null,
        customer: {
          name: '',
          email: '',
          phone: '',
          taxIdentificationNumber: '',
          clientType: undefined,
        },
        currentOrder: null,
        callbackError: null,
        fullJobId: '',
      };
    },

    /**
     * modify retry attempt
     */
    sendRetry() {
      this.retry += 1;
    },

    async sendCloudConsole(storeId: string, message: string) {
      const LOGGER_URL = 'https://kiosks-logger-production-374718288837.us-central1.run.app/';
      const instance = axiosInstanceBuilder(LOGGER_URL).getInstance();

      await instance.post('log', {
        storeId,
        orderId: this.fullJobId,
        message,
      });
    },
  },
});
