import { RouteName } from '../routes/routeName';

export function useReset() {
  const router = useRouter();
  const route = useRoute();
  const cartStore = useCartStoreV2();
  const webhookStore = useWebhookStoreV2();
  const orderTypeStore = useOrderTypeStore();
  const couponStore = useCouponStore();
  const policiesStore = useLegalPolicies();
  const modalStore = useModalStore();
  const tipsController = useTipsController();
  const currencyController = useCurrencyController();
  const tableController = useTableController();

  function flush() {
    cartStore?.clearCart?.();
    webhookStore?.restartWebhook?.();
    orderTypeStore?.clearOrderType?.();
    couponStore?.clearCoupon?.();
    policiesStore?.resetPolicies?.();
    tableController?.clear();
    modalStore?.closeModal();
    tipsController.clear();
    currencyController.clear();
  }

  function flushState() {
    console.warn('reset from:', route.name);
    flush();
    router.replace({ name: RouteName.WELCOME });
  }

  return {
    flushState,
  };
}
