import { TipsStyles } from '@slabcode/kiosks-core/enums';

export const useTipsController = defineStore('tips', () => {
  const metadataStoreV2 = useMetadataStoreV2();
  const { clusterSettings } = storeToRefs(metadataStoreV2);
  const cartStoreV2 = useCartStoreV2();
  const { subtotal: total } = storeToRefs(cartStoreV2);

  const tipSelected = ref<number>(0);

  const calculatedTip = computed(() => (parseFloat((total.value * (tipSelected.value / 100)).toFixed(2))));

  const tips = computed(() => {
    const amounts = clusterSettings.value?.tips?.amounts;
    return amounts ? [amounts.low, amounts.medium, amounts.high, amounts.excellent] : [];
  });
  const hasGamification = computed(() => clusterSettings.value?.tips?.style === TipsStyles.Gamified);
  const hasTipModal = computed(() => clusterSettings.value?.tips?.enabled && tips.value.length > 0);

  function clear() {
    tipSelected.value = 0;
  }

  return { tipSelected, tips, calculatedTip, hasGamification, hasTipModal, clear };
});
